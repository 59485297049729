<template>
  <div>
    <div class="modal-backdrop fade show"></div>
    <div
      class="modal fade show"
      tabindex="-1"
      role="dialog"
      style="display: block"
      @click="closeModal"
    >
      <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document" @click.stop>
        <div class="modal-content" :style="loading ? 'opacity: 0.9; pointer-events: none' : ''">
          <div class="modal-header">
            <h5 class="modal-title">
              Добавить пользователя
              <small class="d-block" v-if="this.id">{{ this.id }}</small>
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" style="display: block" @click="closeModal">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="mb-2">Логин <span class="text-danger">*</span></label>
                  <input type="text" class="form-control" v-model="login" :disabled="!!id" />
                </div>
                <div class="form-group">
                  <label class="mb-2">Пароль <span class="text-danger" v-if="!id">*</span></label>
                  <input type="text" class="form-control" v-model="password" />
                </div>
                <div class="form-group">
                  <label class="mb-2">E-mail</label>
                  <input type="email" class="form-control" v-model="email" />
                </div>
                <div class="form-group">
                  <label class="mb-2">Адрес</label>
                  <input type="text" class="form-control" v-model="address" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="mb-2">Полное имя</label>
                  <input type="text" class="form-control" v-model="name" />
                </div>
                <div class="form-group">
                  <label class="mb-2">Телефон</label>
                  <input type="text" class="form-control" v-model="phone" />
                </div>
                <div class="form-group">
                  <label class="mb-2">Роли <span class="text-danger">*</span></label>
                  <select class="form-control h-120px" multiple v-model="role">
                    <option v-for="item in rolesAvailable" :key="item">{{ item }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              :class="{ disabled: !isValid }"
              @click="saveForm"
              :style="isValid ? '' : 'cursor: not-allowed; color: #ccc;'"
            >
              <span v-if="id && password">Сохранить и сменить пароль</span>
              <span v-else-if="id">Сохранить</span>
              <span v-else>Добавить</span>
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="closeModal"
            >
              Отмена
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import api from "@/core/api";
import { computed } from "@vue/runtime-core";

export default {
  props: ["values"],
  setup(props, context) {
    const id = ref(props.values.id);
    const login = ref(props.values.login);
    const password = ref(props.values.password);
    const email = ref(props.values.data.email);
    const name = ref(props.values.data.name);
    const phone = ref(props.values.data.phone);
    const role = ref(props.values.data.role.map((el) => el.name));
    const address = ref(props.values.data.address);
    const loading = ref(false);

    const rolesAvailable = ref([
      "base",
      "admin",
      "service",
      "tech_partner",
      "device",
      "manager",
      "monitoring",
      "director",
      "curator",
      "dealer",
    ]);

    const isValid = computed(
      () =>
        login.value?.trim() !== "" &&
        (password.value?.trim() !== "" || !!id.value) &&
        role.value?.length > 0 &&
        login.value?.match(/^[^а-яА-Я]+$/) &&
        (password.value?.match(/^[^а-яА-Я]+$/) || !!id.value)
    );

    function closeModal() {
      context.emit("closeModal");
    }

    async function saveForm() {
      if (!isValid.value || loading.value) return;
      loading.value = true;
      try {
        if (id.value !== "") {
          let userObj = {
            login: login.value,
            data: {
              email: email.value,
              name: name.value,
              phone: phone.value,
              role: role.value.map((el) => {
                return {
                  name: el,
                  sub_role: [],
                };
              }),
              address: address.value,
            },
          };
          if (password) userObj.password = password.value;
          await api.updateUser(userObj, id.value);
        } else {
          await api.createUser({
            login: login.value,
            password: password.value,
            data: {
              email: email.value,
              name: name.value,
              phone: phone.value,
              role: role.value.map((el) => {
                return {
                  name: el,
                  sub_role: [],
                };
              }),
              address: address.value,
            },
          });
        }
        context.emit("closeModal");
        context.emit("dataSaved");
        alert("Данные сохранены");
      } catch (error) {
        if (error?.response?.data?.detail) {
          alert(error.response.data.detail);
        } else {
          alert(error.message);
        }
      }
      loading.value = false;
    }

    return {
      id,
      login,
      password,
      email,
      name,
      phone,
      role,
      address,
      loading,
      rolesAvailable,
      isValid,
      closeModal,
      saveForm,
    };
  },
};
</script>
